<template>
	<!-- 联系我们 -->
    <div class="main" :style="{'min-height': mainHeight}">
		  <div class="complaint_main computer">
        <div class="six-title" style="margin-bottom: 30px;">
          <div class="dec">
            <img src="@/assets/images/dec_b_left.png" alt="">
            <span class="big_title">{{node.contactTranslate}}</span>
            <img src="@/assets/images/dec_b_right.png" alt="">
          </div>
        </div>
        <div class="six-main">
          <div class="six-address">
            <div class="six-message">{{node.leaveMsgTranslate}}</div>
            <el-form ref="ruleForm" :rules="rules" :model="formInline">
              <el-row :gutter="40">
                <el-col :span="8">
                  <el-form-item label="" prop="name">
                    <el-input v-model="formInline.name" :placeholder="node.yourNameTranslate"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="" prop="phone">
                    <el-input v-model="formInline.phone" :placeholder="node.yourPhoneTranslate"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="" prop="email">
                    <el-input v-model="formInline.email" :placeholder="node.yourEmailTip"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-form-item label="" prop="desc">
                <el-input type="textarea" v-model="formInline.desc" :autosize="{ minRows: 9, maxRows: 20}" :placeholder="node.writeMsgTranslate"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button class="bt_r" type="primary" @click="onSubmit()" :loading="butLoading">{{node.submitTranslate}}</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
		  </div>
      <div class="phone">
        <div class="phone_complaint_main">
          <div class="six-title">
            <div class="dflex_center">
              <img src="@/assets/images/dec_b_left.png" alt="">
              <span>{{node.contactTranslate}}</span>
              <img src="@/assets/images/dec_b_right.png" alt="">
            </div>
          </div>
          <div class="six-message" style="text-indent: 3.5ch;padding:10px;color:#666;margin-bottom: 2vh;">{{node.leaveMsgTranslate}}</div>
          <van-form ref="vanForm">
					<van-cell-group inset>
						<van-field v-model="formInline.name" class="field_border mb2vh" name="name" label="" :placeholder="node.yourNameTranslate" :rules="[{ required: true, message: getLanguageText(languageType, '请输入您的姓名') }]"/>
					</van-cell-group>
					<van-cell-group inset>
						<van-field v-model="formInline.phone" class="field_border mb2vh" name="phone" label="" :placeholder="node.yourPhoneTranslate" :rules="[{ required: true, message: getLanguageText(languageType, '请输入您的手机号') }]"/>
					</van-cell-group>
					<van-cell-group inset>
						<van-field v-model="formInline.email" class="field_border mb2vh" name="email" label="" :placeholder="node.yourEmailTip" :rules="[{ required: true, message: node.yourEmailTranslate }]"/>
					</van-cell-group>
					<van-cell-group inset>
						<van-field v-model="formInline.desc" class="field_border" rows="2" autosize label="" type="textarea" :placeholder="node.writeMsgTranslate" :rules="[{ required: true, message: getLanguageText(languageType, '请输入留言信息') }]"/>
					</van-cell-group>
					<div style="display: flex;flex-direction: row-reverse;margin-top: 1vh;">
						<van-button type="primary" style="height: 4vh;border-radius: 0.6vh;background-color: #1989fa;border: 1px solid #1989fa;font-size: 2vh;padding: 0 1vh;color: #fff;" @click="onPhoneSubmit">{{node.submitTranslate}}</van-button>
					</div>
				</van-form>
        </div>
        <!-- 联系我们 -->
        <div class="home_relation">
          <div class="content" style="background: #fff;">
            <div class="boxs">
              <div class="p_dec">
                <img src="@/assets/images/dec_b_left.png" alt="">
                <span>{{node.contactTranslate}}</span>
                <img src="@/assets/images/dec_b_right.png" alt="">
              </div>
              <div class="home_relation_main">
                <div class="home_relation_mains" :class="{'home_relation_bor': item.id == 2}" v-for="item in relationList" :key="item.id">
                  <i :class="item.icon" class="icon"></i>
                  <div class="home_relation_text" style="margin-left:20px">
                    <span class="relation_name">{{item.name}}</span>
                    <span class="relation_text">{{item.text}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <phone-menu></phone-menu>
      </div>
    </div>
</template>

<script>
import phoneMenu from '../components/phoneMenu.vue'
import { siteleavemsg } from '@/api/index'
export default {
  data() {
    const validatePass = (rule, valueData, callback) => {
      let value = valueData ? valueData.trim() : '';
      let reg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
      if (value === '' || value === null || value === undefined) {
        callback(new Error(this.node.yourEmailTip))
      } else if (reg.test(value)) {
        callback()
      } else {
        callback(new Error(this.node.yourEmailTranslate));
      }
    };
    return {
      formInline: {},
			mainHeight: '',
			rules: {
        name: [
          { required: true, message: '请输入您的姓名', trigger: 'change' }
        ],
        phone: [
          { required: true, message: '请输入您的手机号', trigger: 'change' }
        ],
        email: [
          { validator: validatePass, trigger: 'blur' }
        ],
        desc: [
          { required: true, message: '请输入留言信息', trigger: 'change' }
        ],
      },
			butLoading: false
    }
  },
  computed: {
    node(){
      return this.$store.state.node
    },
    languageType(){
      return this.$store.state.languageType
    },
    relationList() {
      return this.$store.state.relationList
    },
  },
  components: {
    phoneMenu
  },
  mounted() {
    let maxResult = window.matchMedia('(max-width: 980px)');
   		if (maxResult.matches) {
			this.mainHeight = window.innerHeight - 80 + 'px'
   		} else {
			this.mainHeight = window.innerHeight - document.getElementsByClassName('header')[0].offsetHeight-200 + 'px'
		}
	  this.rules.name[0].message = this.getLanguageText(this.languageType, '请输入您的姓名')
	  this.rules.phone[0].message = this.getLanguageText(this.languageType, '请输入您的手机号')
	  this.rules.desc[0].message = this.getLanguageText(this.languageType, '请输入留言信息')
  },
  methods: {
    // 移动端提交
		onPhoneSubmit() {
			this.$refs.vanForm.validate().then(()=>{
				let data = {
    		  consultant: this.formInline.name,
    		  phone: this.formInline.phone,
    		  email: this.formInline.email,
    		  msg: this.formInline.desc,
    		  siteCode: this.$store.state.siteCode
    		}
    		siteleavemsg(data).then((res) => {
    		  if(res && res.code == 0) {
				    this.$notify({ type: 'success', message: this.getLanguageText(this.languageType, '提交成功') });
    		    this.formInline = {}
    		  }
    		}).catch(err => {
    		  console.log('err', err);
    	  })
    	}).catch(()=>{
			  this.$notify({ type: 'danger', message: this.getLanguageText(this.languageType, '请输入联系信息及留言信息') });
    	})
		},
    // 提交留言
    onSubmit() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          let data = {
            consultant: this.formInline.name,
            phone: this.formInline.phone,
            email: this.formInline.email,
            msg: this.formInline.desc,
            siteCode: this.$store.state.siteCode
          }
          this.butLoading = true;
          siteleavemsg(data).then((res) => {
            if(res && res.code == 0) {
              this.$message.success(this.getLanguageText(this.languageType, '提交成功'));
              this.formInline = {}
              this.$refs['ruleForm'].resetFields();
            }
            this.butLoading = false;
          }).catch(err => {
            this.butLoading = false;
            console.log('err', err);
          })
        } else {
          console.log('error submit!!');
          this.$message.error(this.getLanguageText(this.languageType, '请输入联系信息及留言信息'));
          return false;
        }
      });
    },
	}
}
</script>

<style lang="scss" scoped>
  .mb2vh {
		margin-bottom: 2vh;
	}
  .phone {
		.phone_complaint_main {
			width: 70%;
   			min-width: 43vh;
    		min-height: 46vh;
			background: #fff;
			margin: 0 auto;
   			border-radius: 2.5vh;
   			padding: 2vh;
			/deep/ .van-cell {
				font-size: 3vh;
				line-height: normal;
			}
			/deep/ .van-field__error-message {
				font-size: 1vh;
			}
			.field_border {
				border: 1px solid #000;
			}
		}
	}
  .dflex_center {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2vh 0;
    span {
      font-size: 2vh !important;
      height: auto !important;
    }
    img {
      width: 15vw;
      height: 3vw;
    }
  }
	.main {
		width: 100%;
		display: flex;
    align-items: center;
    justify-content: center;
		background: url('~@/assets/images/background-img.jpg') no-repeat center center;
		background-size: 100% 100%;
		background-attachment: fixed;
    overflow: hidden;
		.complaint_main {
			width: 70%;
   			min-width: 900px;
    		min-height: 600px;
			background: #fff;
   			border-radius: 25px;
   			padding: 40px;
		}
	}
	.parent_name {
		font-size: 20px;
    	font-weight: 600;
	}
	.main_in {
		padding: 10px;
	}
    .six-title {
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      .six-img {
        width: 697px;
        height: 104px;
      }
    }
    .six-main {
      background: #fff;
      .six-address {
        width: 100%;
        height: 357px;
        .six-message {
          width: 100%;
          height: 40px;
          font-size: 20px;
          margin-bottom: 20px;
          text-indent: 3.5ch;
          color: #666;
          display: inline-block;
        }
        /deep/ .el-input__inner {
          border-radius: 10px;
        }
        /deep/ .el-textarea__inner {
          border-radius: 10px;
        }
      }
      .six-compay {
        width: 100%;
        height: 108px;
        line-height: 108px;
        font-size: 30px;
        color: #151515;
        padding: 0 30px;
        box-sizing: border-box;
        overflow: hidden;
        .six-text {
          width: 100%;
          height: 100%;
          display: inline-block;
          border-bottom: 1px solid #E6E6E6;
        }
      }
      .six-info {
        height: 180px;
        padding: 40px 30px 50px;
        box-sizing: border-box;
        font-size: 22px;
        font-weight: 300;
        color: #4A4A4A;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        .six-item {
          width: 40%;
          display: flex;
          align-items: center;
          font-size: 24px;
          &:nth-child(1),  &:nth-child(2){
            margin-bottom: 30px;
          }
          &:nth-child(1) {
            width: 60%;
          }
          &:nth-child(3) {
            width: 100%;
          }
        }
        .address {
          display: inline-block;
          width: 24px;
          height: 24px;
          background: url('~@/assets/images/about/icon_address.png') no-repeat;
          background-size: contain;
          margin-right: 10px;
        }
        .phone {
          display: inline-block;
          width: 24px;
          height: 24px;
          background: url('~@/assets/images/about/icon_phone.png') no-repeat;
          background-size: contain;
          margin-right: 10px;
        }
        .email {
          display: inline-block;
          width: 24px;
          height: 24px;
          background: url('~@/assets/images/about/icon_email.png') no-repeat;
          background-size: contain;
          margin-right: 10px;
          position: relative;
          top: 3px;
        }
        .net {
          display: inline-block;
          width: 24px;
          height: 24px;
          background: url('~@/assets/images/about/icon_url.png') no-repeat;
          background-size: contain;
          margin-right: 10px;
          position: relative;
          top: 4px;
        }
      }
    }
</style>