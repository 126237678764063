<template>
    <!-- 客户登录 -->    
    <div class="main" :style="{'height': mainHeight}">
        <div class="complaint_main">
            <el-row class="main_left">
                <el-col :span="12" class="h200 mb60">
                    <div :class="['system', selectedIndex == 1 ? 'item_selected' : '']" @click="getSelect(1)">
                        <img src="../assets/images/psj.png" alt="" width="90">
                        <div>派速捷云平台</div>
                    </div>
                </el-col>
                <el-col :span="12" class="h200 mb60">
                    <div :class="['system', selectedIndex == 2 ? 'item_selected' : '']" @click="getSelect(2)">
                        <img src="../assets/images/yc.png" alt="" width="90">
                        <div>易仓系统</div>
                    </div>
                </el-col>
                <el-col :span="12" class="h200 mb60">
                    <div :class="['system', selectedIndex == 3 ? 'item_selected' : '']" @click="getSelect(3)">
                        <img src="../assets/images/yjt.png" alt="" width="90">
                        <div>易境通系统</div>
                    </div>
                </el-col>
                <el-col :span="12" class="h200 mb60">
                    <div :class="['system', selectedIndex == 4 ? 'item_selected' : '']" @click="getSelect(4)">
                        <img src="../assets/images/gd.png" alt="" width="90">
                        <div>工单系统</div>
                    </div>
                </el-col>
            </el-row>
            <div class="main_right">
                <div class="login_top">
                    <span :class="[loginType == 1 ? 'color_0d63ee' : 'color_000']" @click="getLoginType(1)">{{getLanguageText(languageType, '客户登录')}}</span>
                    <span>|</span>
                    <span :class="[loginType == 2 ? 'color_0d63ee' : 'color_000']" @click="getLoginType(2)">{{getLanguageText(languageType, '用户登录')}}</span>
                </div>
                <el-form ref="dataForm" :model="dataForm" label-width="0px" style="margin-top: 40px;">
                    <el-form-item prop="userName" class="mb30">
                        <el-input v-model="dataForm.userName" prefix-icon="el-icon-user" disabled class="loginInput" :placeholder="getLanguageText(languageType, '请输入帐号')" clearable size="large" ></el-input>
                    </el-form-item>
                    <el-form-item prop="password" class="mb30">
                        <el-input v-model="dataForm.password" prefix-icon="el-icon-lock" disabled class="loginInput" type="password" :placeholder="getLanguageText(languageType, '请输入密码')" clearable size="large"></el-input>
                    </el-form-item>
                    <el-form-item class="pt-40">
                        <el-button type="primary" style="width: 100%" @click="getSubmit()">{{getLanguageText(languageType, '前往登录')}}</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
			mainHeight: '',
			butLoading: false,
            selectedIndex: 1,
            dataForm: {
                userName: '',
                password: ''
            },
            loginType: 1
        }
    },
    computed: {
        node(){
            return this.$store.state.node
        },
        languageType(){
            return this.$store.state.languageType
        },
    },
    mounted() {
		this.mainHeight = window.innerHeight - document.getElementsByClassName('header')[0].offsetHeight - document.getElementsByClassName('footer')[0].offsetHeight + 'px'
    },
    methods: {
        getSelect(index) {
            this.selectedIndex = index
        },
        getLoginType(index) {
            this.loginType = index
        },
        getSubmit() {
            let url = ''
            switch (this.selectedIndex) {
                case 1:     // 派速捷系统
                    if(this.loginType == 1) {
                        url = 'https://toms.parceljet.com/#/login'
                    } else {
                        url = 'https://admin.parceljet.com/#/login'
                    }
                    break;
                case 2:     // 易仓系统
                    if(this.loginType == 1) {
                        url = 'https://oms.parceljet.com'
                    } else {
                        url = 'https://wms.parceljet.com'
                    }
                    break;
                case 3:     // 易境通系统
                    if(this.loginType == 1) {
                        url = 'https://psjoms.parceljet.com/oversea_oms/#/login'
                    } else {
                        url = 'https://psjwms.parceljet.com/oversea_wms/#/login'
                    }
                    break;
                case 4:     // 工单系统
                    if(this.loginType == 1) {
                        url = 'http://wms.parceljet.com/fee1000/index.jsp'
                    } else {
                        url = 'http://wms.parceljet.com/fee1000/index.jsp'
                    }
                    break;
                default:
            }
            window.location.href = url;
        }
    }
}
</script>

<style lang="scss" scoped>
	.main {
		width: 100%;
		display: flex;
    	align-items: center;
    	justify-content: center;
		background: url('~@/assets/images/background-img.jpg') no-repeat center center;
		background-size: 100% 100%;
		background-attachment: fixed;
        overflow: hidden;
		.complaint_main {
			width: 85%;
   			min-width: 900px;
    		min-height: 700px;
   			padding: 20px;
            padding-right: 130px;
            display: flex;
            align-items: center;
            justify-content: space-between;
		}
        .main_left {
            width: 700px;
            height: 600px;
            padding-top: 100px;
            padding-left: 100px;
            .system {
                width: 200px;
                height: 174px;
                background: #fff;
                text-align: center;
                border-radius: 15px;
                box-shadow: 0px 6px 8px 6px rgba(225, 225, 225, 0.23);
                cursor: pointer;
                img {
                    position: relative;
                    top: -45px;
                    border-radius: 15px;
                    box-shadow: 0px 3px 5px 4px rgba(81, 63, 63, 0.23);
                }
                div {
                    position: relative;
                    top: -20px;
                    font-size: 26px;
                    font-weight: 600;
                }
            }
        }
        .main_right {
            width: 400px;
            height: 340px;
            border-radius: 20px;
            padding: 30px;
            background-color: #fff;
            .login_top {
                text-align: center;
                font-size: 22px;
                margin-bottom: 24px;
                display: flex;
                justify-content: space-evenly;
            }
            .color_0d63ee {
                color: #0d63ee;
                cursor: pointer;
            }
            .color_000 {
                color: #000;
                cursor: pointer;
            }
            .loginInput {
                /deep/ .el-input__inner {
                    border-radius: 30px;
                    padding: 0 20px;
                    font-size: 18px;
                    padding-left: 30px;
                }
                /deep/ .el-input__prefix {
                    font-size: 20px;
                    padding-left: 4px;
                }
            }
        }
	}
    .mb30 {
        margin-bottom: 30px;
    }
    .item_selected {
        background: #77b6ed !important;
        color: #fff;
    }
	.parent_name {
		font-size: 20px;
    	font-weight: 600;
	}
	.main_in {
		padding: 10px;
	}
    .h200 {
        height: 200px;
    }
    .mb60 {
        margin-bottom: 60px;
    }
</style>